
import { defineComponent } from 'vue';
import Panel from '@/components/layout/Panel.vue';
import ViewMixin from '@/mixins/ViewMixin';
import TextField from '@/components/controls/TextField.vue';
import Button from '@/components/controls/Button';
import Dialog from '@/components/dialogs/Dialog.vue';
import EditItemCategory from '@/components/ingame/itemCategory/EditItemCategory.vue';
import * as itemCategoryService from '@/services/ingame/itemCategoryService';
import { ItemCategory } from '@/interfaces/ingame/itemCategory';
import LoadingIndicatorBeam from '@/components/loading/LoadingIndicatorBeam.vue';
import DataItem from '@/components/layout/DataItem.vue';
import { ROLE_MODERATOR } from '@/constants/roles';

interface Data {
    searchTerm: string;
    showEditDialog: boolean;
    editId: string | null;
    isLoading: boolean;
    itemCategories: ItemCategory[];
}

export default defineComponent({
    name: 'ItemCategories',
    components: {
        Dialog,
        Button,
        Panel,
        TextField,
        EditItemCategory,
        LoadingIndicatorBeam,
        DataItem,
    },
    mixins: [ViewMixin],
    data: (): Data => ({
        searchTerm: '',
        showEditDialog: false,
        editId: null,
        isLoading: false,
        itemCategories: [],
    }),
    computed: {
        isModerator(): boolean {
            return this.$store.getters['authentication/hasOneRoles']([ROLE_MODERATOR]);
        },
    },
    methods: {
        showCreate(): void {
            this.editId = null;
            this.showEditDialog = true;
        },
        showEdit(id: string) {
            this.editId = id;
            this.showEditDialog = true;
        },
        hideEditDialog(refreshData = false): void {
            this.showEditDialog = false;
            this.editId = null;
            if (refreshData) {
                this.loadItemCategories();
            }
        },
        async refreshData(): Promise<void> {
            await this.loadItemCategories();
        },
        async loadItemCategories(): Promise<void> {
            this.isLoading = true;
            try {
                const response = await itemCategoryService.getMultiple({
                    pageSize: -1,
                    searchQuery: this.searchTerm,
                });
                this.itemCategories = response.data;
            } catch (_) {
                // do nothing
            }
            this.isLoading = false;
        },
    },
    created(): void {
        this.setPageTitle([this.$t('itemCategories'), this.$t('ingame')]);
        this.refreshData();
    },
});
